import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import {
    Attendance,
    CustomTableRow,
    Employee,
    GlobalState,
    Transaction,
} from '../interfaces/Interfaces';

export const useGlobalStore = create<GlobalState>()(
    devtools(
        persist(
            (set) => ({
                authenticated: false,
                setAuthenticated: (authed: boolean) => {
                    setTimeout(() => {
                        useGlobalStore.persist.clearStorage();
                    }, 18000000);
                    return set(() => ({
                        authenticated: authed,
                        username: '',
                        employee: undefined,
                        attendances: undefined,
                        currentAttendance: undefined,
                        inTime: undefined,
                        outTime: undefined,
                        activeJobsTableData: undefined,
                    }));
                },
                username: '',
                setUsername: (username: string) =>
                    set(() => ({ username: username })),
                employee: undefined,
                setEmployee: (emp: Employee | undefined) =>
                    set(() => ({ employee: emp })),
                attendances: undefined,
                setAttendances: (arrayAttendances: Array<Attendance>) =>
                    set(() => ({ attendances: arrayAttendances })),
                currentAttendance: undefined,
                setLastWeekAttendances: (
                    lasWeekAttendances: Array<CustomTableRow>,
                ) => set(() => ({ lastWeekAttendances: lasWeekAttendances })),
                lastWeekAttendances: undefined,
                setCurrentWeekAttendances: (
                    curWeekAttendances: Array<CustomTableRow>,
                ) =>
                    set(() => ({ currentWeekAttendances: curWeekAttendances })),
                currentWeekAttendances: undefined,
                setCurrentAttendance: (curAttendance: Attendance) =>
                    set(() => ({ currentAttendance: curAttendance })),
                inTime: '',
                setInTime: (clockIn: string) =>
                    set(() => ({ inTime: clockIn })),
                outTime: '',
                setOutTime: (clockOut: string) =>
                    set(() => ({ outTime: clockOut })),
                activeJobsTableData: undefined,
                setActiveJobsTableData: (
                    newActiveJobsTableData: Array<Transaction> | undefined,
                ) =>
                    set(() => ({
                        activeJobsTableData: newActiveJobsTableData,
                    })),
                clearState: () =>
                    set(() => ({
                        authenticated: false,
                        username: '',
                        employee: undefined,
                        attendances: undefined,
                        currentAttendance: undefined,
                        inTime: undefined,
                        outTime: undefined,
                        activeJobsTableData: undefined,
                    })),
            }),
            {
                name: 'useGlobalStore',
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
    ),
);
