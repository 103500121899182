import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import {
    clockIn,
    clockOut,
    fetchAttendances,
    fetchCurrentWeekAttendances,
    fetchLastWeekAttendances,
    fetchUserTransactions,
    formatDateWithTimeAndT,
    formatTimeNoDateIncludeTZ,
    formatTimeWithMeridiemNoDate,
    toast,
} from '../utilities/Utilites';
import { DateTime } from 'luxon';
import {
    Attendance,
    CustomTableRow,
    Transaction,
} from '../interfaces/Interfaces';
import { useGlobalStore } from '../utilities/GlobalState';
import WarningIcon from '@mui/icons-material/Warning';

export const TimeClock = () => {
    const globalState = useGlobalStore((state) => state);

    const [time, setTime] = React.useState(
        new Date(Date.now()).toLocaleTimeString('en-US', {
            timeZone: 'America/New_York',
        }),
    );

    const [login, setLogin] = React.useState('--:--');
    const [logout, setLogout] = React.useState('--:--');
    const [loading, setLoading] = React.useState(false);
    const [activeTransactions, setActiveTransaction] = React.useState<
        Array<Transaction>
    >([]);

    React.useEffect(() => {
        setTimeout(() => {
            setTime(formatTimeWithMeridiemNoDate(DateTime.now()));
        }, 1000);
    }, [time]);

    const getAttendances = (employee: string) => {
        if (!loading) {
            return fetchAttendances(employee).then((res: any) => {
                return res.data;
            });
        } else {
            return new Promise((res) => res(''));
        }
    };

    const getTransactionsToday = (employee: string) => {
        return fetchUserTransactions(employee, {
            workDateStart: formatDateWithTimeAndT(
                DateTime.now().setZone('America/New_York').set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                }),
            ),
        }).then((res: any) => {
            return res.data;
        });
    };

    const getPunchTimes = () => {
        if (globalState?.employee?.employee) {
            return getAttendances(globalState.employee.employee)
                .then((res: any) => {
                    let currentAttendance = {
                        adjusted_Login: '',
                        adjusted_Logout: '',
                        attendance: '',
                        attendance_Type: 0,
                        break_Minutes: 0,
                        doubleOT_Minutes: 0,
                        employee: '',
                        last_Updated: '',
                        lock_Times: false,
                        login: '',
                        logout: '',
                        oT_Minutes: 0,
                        regular_Minutes: 0,
                        source: 1,
                        work_Date: '',
                    };

                    if (currentAttendance?.last_Updated?.length < 1) {
                        currentAttendance = res[0];
                    }

                    for (let i = 0; i < res.length; i++) {
                        if (
                            DateTime.fromJSDate(
                                new Date(currentAttendance?.adjusted_Login),
                            ).toMillis() <=
                            DateTime.fromJSDate(
                                new Date(res?.[i]?.adjusted_Login),
                            ).toMillis()
                        ) {
                            setLogin(res[i]?.adjusted_Login);
                            if (res[i]?.adjusted_Logout === null) {
                                setLogout('--:--');
                            } else {
                                setLogout(res[i]?.adjusted_Logout);
                            }
                            currentAttendance = res[i];
                        }
                    }
                    globalState.setAttendances(res);
                    globalState.setCurrentAttendance(currentAttendance);
                    globalState.setInTime(currentAttendance?.adjusted_Login);
                    globalState.setOutTime(currentAttendance?.adjusted_Logout);
                    fetchCurrentWeekAttendances(
                        globalState?.employee?.employee || '',
                    )
                        .then((resAtt: Array<CustomTableRow>) => {
                            globalState.setCurrentWeekAttendances(resAtt);
                            getTransactionsToday(
                                globalState?.employee?.employee || '',
                            )
                                .then((transRes) => {
                                    const active = transRes.filter(
                                        (trans: any) => {
                                            return (
                                                trans.transaction_Type === 10
                                            );
                                        },
                                    );
                                    setActiveTransaction(active);
                                })
                                .catch((e: any) => console.error(e));
                        })
                        .catch((e: any) => console.error(e));

                    fetchLastWeekAttendances(
                        globalState?.employee?.employee || '',
                    )
                        .then((resLast: Array<CustomTableRow>) => {
                            globalState.setLastWeekAttendances(resLast);
                        })
                        .catch((e: any) => console.error(e));

                    return currentAttendance;
                })
                .catch((e: any) => console.error(e));
        } else {
            toast('Missing Employee Data!', 'error');
            return new Promise((res) => res('failed'));
        }
    };

    React.useEffect(() => {
        if (!loading) {
            setLoading(true);
            getPunchTimes().then(() => {
                setLoading(false);
            });
        }
    }, [globalState?.employee?.employee]);

    const handleClockIn = () => {
        if (globalState?.employee?.employee?.length && !loading) {
            setLoading(true);
            clockIn(globalState.employee.employee).then(() => {
                getPunchTimes().then(() => {
                    setLoading(false);
                });
            });
        } else {
            toast('Missing Employee Data!', 'error');
        }
    };

    const handleClockOut = () => {
        if (
            globalState?.employee?.employee?.length &&
            globalState?.currentAttendance
        ) {
            clockOut(
                globalState.employee.employee,
                globalState.currentAttendance,
                (attendance: Attendance) =>
                    globalState.setCurrentAttendance(attendance),
            ).then(() => {
                if (globalState?.currentAttendance && !loading) {
                    setLoading(true);
                    getPunchTimes().then(() => {
                        setLoading(false);
                    });
                }
            });
        } else {
            toast('Missing Employee Data!', 'error');
        }
    };

    const disableClockInButton = () => {
        let disabled = true;

        if (globalState?.attendances && globalState?.attendances?.length < 1) {
            disabled = false;
        } else if (login?.length > 6 && logout?.length > 6) {
            disabled = false;
        }

        if (logout == '--:--' && login !== '--:--') {
            disabled = true;
        }

        return disabled;
    };

    return (
        <Card
            sx={{
                padding: '10px 10px 10px 10px',
                margin: '30px 10px 0 10px',
            }}
        >
            <div className="live-clock">{time}</div>
            {!loading ? (
                <>
                    <CardContent>
                        <Grid
                            container
                            style={{ textAlign: 'center' }}
                            spacing={2}
                        >
                            <Grid item xs={6} md={6}>
                                <Typography variant="h5" component="div">
                                    {login && login?.length > 5
                                        ? formatTimeNoDateIncludeTZ(
                                              DateTime.fromISO(login),
                                          )
                                        : login}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Typography variant="h5" component="div">
                                    {logout && logout?.length > 5
                                        ? formatTimeNoDateIncludeTZ(
                                              DateTime.fromISO(logout),
                                          )
                                        : logout}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions style={{ alignItems: 'center' }}>
                        <Grid
                            container
                            style={{ textAlign: 'center' }}
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={handleClockIn}
                                    disabled={disableClockInButton()}
                                    color="secondary"
                                >
                                    In
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={handleClockOut}
                                    disabled={
                                        logout !== '--:--' ||
                                        login === '--:--' ||
                                        activeTransactions?.length > 0
                                    }
                                >
                                    OUT
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </>
            ) : (
                <></>
            )}
            {activeTransactions?.length ? (
                <Grid container style={{ textAlign: 'center' }} spacing={2}>
                    <Grid item xs={2}>
                        <WarningIcon color={'warning'} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h5"
                            component="div"
                            style={{
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            {`Caution: ${activeTransactions?.length || 0} job${
                                activeTransactions?.length > 1 ? 's are' : ' is'
                            } active.`}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <WarningIcon color={'warning'} />
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
        </Card>
    );
};
