import * as React from 'react';
import { CurrentWeek } from './CurrentWeek';
import { LastWeek } from './LastWeek';
import { TimeClock } from './TimeClock';
import { defaultTheme } from '../utilities/Utilites';
import ThemeProvider from '@mui/system/ThemeProvider';

export const TimeManagement = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <TimeClock />
            <CurrentWeek />
            <LastWeek />
        </ThemeProvider>
    );
};
