import { AxiosResponse } from 'axios';
import { Get, Post } from './Requests';
import { Alert, Link, Typography, createTheme } from '@mui/material';
import * as React from 'react';
import { red } from '@mui/material/colors';
import {
    Attendance,
    CustomTableRow,
    StartStopJob,
    StopJobTime,
    Transaction,
    TransactionData,
    fetchJobsProps,
} from '../interfaces/Interfaces';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

const authenticateUser = (username: string, password: string) => {
    // Check if credentials are valid before using
    if (username && password) {
        Post('/Authenticate', {
            Username: username,
            Password: password,
            canCache: true,
        }).then((res: AxiosResponse) => {
            if (res.status === 200) {
                return res;
            } else {
                throw new Error(res.toString());
            }
        });
    } else {
        throw new Error('Credentials are invalid');
    }
};

const toast = (
    content: string,
    type: 'error' | 'warning' | 'info' | 'success',
) => {
    return (
        <Alert variant="filled" className="toast" severity={type}>
            {content}
        </Alert>
    );
};

// TODO remove ANY
// eslint-disable-next-line
const Copyright = (props: any) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            <Link color="inherit" href="https://aptmfg.com/">
                APT Manufacturing Solutions
            </Link>
            {' © '}
            {DateTime.now().setZone('America/New_York').toFormat('yyyy')}
        </Typography>
    );
};

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: red[500],
        },
        secondary: {
            main: red[500],
        },
    },
});

const formatDateNoTime = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const formatDateNoTimeMonth = (date: DateTime) => {
    return date.toFormat('MM-dd-yyyy'); // MM-dd-yyyy
};

const formatTimeNoDate = (date: DateTime) => {
    return date.toFormat('h:mm:ss'); // HH:mm:ss
};

const formatTimeWithMeridiemNoDate = (date: DateTime) => {
    return date.toFormat('h:mm:ss a'); // HH:mm:ss AM/PM
};

const formatTimeNoDateIncludeTZ = (date: DateTime) => {
    return date.toFormat('h:mm a ZZZZ'); // h:mm PM EDT (12 hour format)
};

const formatTimeWithSecondsNoDateIncludeTZ = (date: DateTime) => {
    return date.toFormat('h:mm:ss a ZZZZ'); // h:mm:ss PM EDT (12 hour format)
};

const formatDateWithTime = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd HH:mm:ss'); // yyyy-MM-dd HH:mm:ss
};

const formatDateWithTimeAndT = (date: DateTime) => {
    return date.toFormat("yyyy-MM-dd'T'HH:mm:ss"); // yyyy-MM-dd'T'HH:mm:ss
};

const formatDateWithTimeAndTRounded = (date: DateTime) => {
    return date.startOf('minute').toFormat("yyyy-MM-dd'T'HH:mm:ss"); // yyyy-MM-dd'T'HH:mm:ss
};

const fetchEmployee = (username: string) => {
    return Get('/GetEmployeeUsername', {
        employeeUsername: username,
        canCache: false,
    });
};

const convertMinutesToHours = (mins: number) => {
    const hours = mins / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return rhours + ' hrs ' + rminutes + ' mins';
};

const getDateThisWeekStart = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .startOf('week')
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const getDateLastWeekStart = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .minus({ days: 7 })
        .startOf('week')
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const getDateLastWeekEnd = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .minus({ days: 7 })
        .startOf('week')
        .plus({ days: 6 })
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const fetchJobs = (props?: fetchJobsProps) => {
    return Get('/GetJobs', {
        orderDateStart: props?.job,
        orderDateEnd: props?.job,
        job: props?.job,
        quote: props?.quote,
        customer: props?.customer,
        topLevelJob: props?.topLevelJob,
        description: props?.description,
        salesCode: props?.salesCode,
        statusCode: 'Active',
        partNumber: props?.partNumber,
        canCache: true,
    });
};

const fetchUserTransactions = (
    employee?: string,
    options?: {
        workDateStart?: string;
        workDateEnd?: string;
        workTransactions?: string;
    },
): Promise<AxiosResponse<Array<Transaction>>> => {
    return Get('/GetTransactions', {
        employee: employee,
        workDateStart: options?.workDateStart,
        workDateEnd: options?.workDateEnd,
        workTransactions: options?.workTransactions,
    });
};

const fetchWorkCenter = (job: string) => {
    return Get('/GetJobOperations', {
        job: job,
        canCache: false,
    });
};

const fetchAttendances = (employee: string) => {
    const date = DateTime.now()
        .setZone('America/New_York')
        .minus({ days: 6 })
        .toFormat('yyyy-MM-dd'); // subtract 6 days
    return Get('/GetAttendances', {
        workDateStart: date,
        employee: employee,
    });
};

const fetchAttendanceRange = (
    employee: string,
    startDate: string,
    endDate: string,
) => {
    return Get('/GetAttendances', {
        workDateStart: startDate, //yyyy-MM-dd
        workDateEnd: endDate, //yyyy-MM-dd
        employee: employee,
    });
};

const fetchLastWeekAttendances = (employee: string) => {
    return fetchAttendanceRange(
        employee,
        getDateLastWeekStart(),
        getDateLastWeekEnd(),
    ).then((res: any) => {
        const fetch = res.data
            .map((attendance: Attendance) => {
                return {
                    date: attendance?.work_Date,
                    inTime: attendance?.adjusted_Login,
                    outTime: attendance?.adjusted_Logout,
                    regMinutes: attendance?.regular_Minutes,
                    oTMinutes: attendance?.oT_Minutes,
                    dTMinutes: attendance?.doubleOT_Minutes,
                    lastUpdate: attendance?.last_Updated,
                };
            })
            .sort((a: CustomTableRow, b: CustomTableRow) => {
                return a.lastUpdate < b.lastUpdate
                    ? -1
                    : a.lastUpdate > b.lastUpdate
                    ? 1
                    : 0;
            });
        return fetch;
    });
};

const fetchCurrentWeekAttendances = (employee: string) => {
    return fetchAttendanceRange(
        employee,
        getDateThisWeekStart(),
        formatDateNoTime(DateTime.now().setZone('America/New_York')),
    ).then((res: any) => {
        const fetch = res.data
            .map((attendance: Attendance) => {
                return {
                    date: attendance?.work_Date,
                    inTime: attendance?.adjusted_Login,
                    outTime: attendance?.adjusted_Logout,
                    regMinutes: attendance?.regular_Minutes,
                    oTMinutes: attendance?.oT_Minutes,
                    dTMinutes: attendance?.doubleOT_Minutes,
                    lastUpdate: attendance?.last_Updated,
                };
            })
            .sort((a: CustomTableRow, b: CustomTableRow) => {
                return a.lastUpdate < b.lastUpdate
                    ? -1
                    : a.lastUpdate > b.lastUpdate
                    ? 1
                    : 0;
            });
        return fetch;
    });
};

const startJobTime = (jobData: Partial<StartStopJob>) => {
    const body = {
        Employee: jobData.Employee,
        Job: jobData.Job,
        Operation: jobData?.Operation?.toString(),
        Ghosted: jobData.Ghosted,
        Is_Run: jobData.Is_Run ? 1 : 0,
        // Completed_Quantity: jobData.Completed_Quantity,
        // Scrapped_Quantity: jobData.Scrapped_Quantity,
        // Percent_Complete: jobData.Percent_Complete,
        // Operation_Complete: jobData.Operation_Complete,
        // Is_Rework: jobData.Is_Rework ? 1 : 0,
        // Transaction_Data: jobData.Transaction_Data,
        // Job_Operation_Time: jobData.Job_Operation_Time,
    };

    return Post('/StartJobTime', body);
};

const stopJobTime = (jobData: StopJobTime) => {
    return Post('/StopJobTime', jobData);
};

interface AttendanceAPI {
    Attendance: string;
    Employee: string;
    Work_Date: string;
    Login: string;
    Adjusted_Login: string;
    Logout: string;
    Adjusted_Logout: string;
    Regular_Minutes: number;
    OT_Minutes: number;
    DoubleOT_Minutes: number;
    Break_Minutes: number;
    Attendance_Type: number;
    Lock_Times: boolean;
    Source: number;
    Last_Updated: string;
}
const clockOut = (
    employee: string,
    currentAttendance: Attendance,
    setCurrentAttendance: (currentAttendance: Attendance) => void,
) => {
    return Get('/GetTransactionDatas', {
        employee: currentAttendance.employee,
        workDateStart: currentAttendance.work_Date,
        canCache: true,
    }).then((transRes: AxiosResponse<Array<TransactionData>>) => {
        const attendance: Attendance = currentAttendance;

        attendance.logout = formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        );
        attendance.adjusted_Logout = formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        );
        attendance.last_Updated = formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        );
        attendance.regular_Minutes =
            Math.abs(
                DateTime.fromISO(attendance.adjusted_Login).diffNow('minutes')
                    .minutes,
            ) || 0;

        const transaction: TransactionData = transRes.data.filter((trans) => {
            return trans.linked_Tran_String === currentAttendance.attendance;
        })[0];
        transaction.transaction_Type = 16;
        transaction.transaction_End = formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        );
        transaction.last_Updated = formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        );

        const mappedAttendance: AttendanceAPI = {
            Attendance: currentAttendance.attendance,
            Employee: employee,
            Work_Date: currentAttendance.work_Date,
            Login: currentAttendance.login,
            Adjusted_Login: currentAttendance.adjusted_Login,
            Logout: formatDateWithTimeAndT(
                DateTime.now().setZone('America/New_York'),
            ),
            Adjusted_Logout: formatDateWithTimeAndTRounded(
                DateTime.now().setZone('America/New_York'),
            ),
            Regular_Minutes:
                Math.abs(
                    DateTime.fromISO(attendance.adjusted_Login).diffNow(
                        'minutes',
                    ).minutes,
                ) || 0,
            OT_Minutes: currentAttendance.oT_Minutes,
            DoubleOT_Minutes: currentAttendance.doubleOT_Minutes,
            Break_Minutes: currentAttendance.break_Minutes,
            Attendance_Type: currentAttendance.attendance_Type,
            Lock_Times: currentAttendance.lock_Times,
            Source: currentAttendance.source,
            Last_Updated: formatDateWithTimeAndT(
                DateTime.now().setZone('America/New_York'),
            ),
        };
        return Promise.all([
            Post('/UpdateAttendance', mappedAttendance),
            Post('/UpdateClockMinutes', mappedAttendance),
            Post('/UpdateTransactionData', transaction),
        ]).then(() => {
            setCurrentAttendance(attendance);
            return fetchAttendances(employee).then((res: any) => {
                return res.data;
            });
        });
    });
};

const clockIn = (employee: string) => {
    const attendance: Attendance = {
        attendance: uuidv4(),
        employee: employee,
        work_Date: formatDateNoTime(DateTime.now().setZone('America/New_York')),
        login: formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        ),
        adjusted_Login: formatDateWithTimeAndTRounded(
            DateTime.now().setZone('America/New_York'),
        ),
        logout: null,
        adjusted_Logout: null,
        regular_Minutes: 0,
        oT_Minutes: 0,
        doubleOT_Minutes: 0,
        break_Minutes: 0,
        attendance_Type: 0,
        lock_Times: false,
        source: 1,
        last_Updated: formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        ),
    };

    // Create a new transaction_data record

    const transaction: TransactionData = {
        transaction_Data: uuidv4(),
        transaction_Type: 15,
        employee: employee,
        work_Date: formatDateNoTime(DateTime.now().setZone('America/New_York')),
        terminal_ID: null,
        status: 0,
        transaction_Start: formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        ),
        transaction_End: null,
        error_ID: null,
        error_Text: null,
        linked_Tran_Int: null,
        linked_Tran_String: attendance.attendance,
        target_Int: null,
        target_String: null,
        job: null,
        material: null,
        quantity: null,
        document: null,
        location: null,
        location_1: null,
        lot: null,
        lot_1: null,
        po: null,
        pO_Line: null,
        last_Updated: formatDateWithTimeAndT(
            DateTime.now().setZone('America/New_York'),
        ),
        close: null,
        previous_Transaction_Start: null,
        previous_Transaction_End: null,
        modified_By_HR: false,
    };
    return Promise.all([
        Post('/InsertAttendance', attendance),
        Post('/InsertTransactionData', transaction),
    ]).then(() => {
        return fetchAttendances(employee).then((res: any) => {
            return res.data;
        });
    });
};

export {
    authenticateUser,
    toast,
    defaultTheme,
    Copyright,
    formatDateNoTime,
    formatDateWithTime,
    clockIn,
    fetchAttendances,
    fetchEmployee,
    formatTimeNoDate,
    clockOut,
    fetchAttendanceRange,
    getDateLastWeekStart,
    getDateLastWeekEnd,
    getDateThisWeekStart,
    convertMinutesToHours,
    formatTimeNoDateIncludeTZ,
    formatDateNoTimeMonth,
    fetchJobs,
    fetchWorkCenter,
    fetchUserTransactions,
    startJobTime,
    stopJobTime,
    formatDateWithTimeAndT,
    formatTimeWithSecondsNoDateIncludeTZ,
    formatTimeWithMeridiemNoDate,
    fetchCurrentWeekAttendances,
    fetchLastWeekAttendances,
};
